<template>
  <div class="vx-row mb-6">
    <div class="vx-col w-full">
      <label>{{ label }}</label>
    </div>
    <div class="vx-col w-full">
      <Tinymce v-model="text"/>
      <slot></slot>
    </div>
  </div>
</template>

<script>

import Tinymce from '@/components/general-form/Tinymce'

export default {
  name: 'PageBody',
  components: {Tinymce},
  props: {
    value: {},
    label: {
      type: String,
      default: 'Continut pagina'
    }
  },
  computed: {
    text: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style>
/*noinspection CssUnusedSymbol*/
.ck-editor__editable_inline {
  min-height: 400px;
}
</style>
